<template>
  <div
    class="text-center"
  >
    <v-dialog
      v-model="showPasswordDialog"
      transition="dialog-top-transition"
      width="600"
    >
      <v-card
        outlined
        elevation="8"
      >
        <v-card-title
          class="headline font-weight-bold"
        >
          Log Masuk
        </v-card-title>

        <v-divider
        ></v-divider>

        <v-card-text
        >
          {{ params.message }}
        </v-card-text>

        <div
          class="pa-8"
        >
          <!-- <p class="h6 font-weight-bold">Log Masuk</p> -->
          <!-- <span>{{ params.message }}</span> -->

          <ValidationObserver
            ref="passwordAuth"
          >
            <v-form
              class="font-weight-bold font-size-sm"
              @submit.prevent="onFinishPassword()"
            >
              <!-- <p class="h6 font-weight-bold">E-mel</p> -->
              <v-row
              >
                <v-col
                  cols="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="E-mel"
                    rules="required"
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mel"
                      hide-details="auto"
                      prepend-inner-icon="mdi-email-variant"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <!-- </v-row> -->

                <!-- <p class="h6 font-weight-bold">Kata laluan</p> -->
                <!-- <v-row> -->
                <v-col
                  cols="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kata laluan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="password"
                      label="Kata Laluan"
                      hide-details="auto"
                      prepend-inner-icon="mdi-key-variant"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :error-messages="errors"
                      @click:append="showPassword = !showPassword"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="
                      py-0
                      d-flex
                      flex-wrap
                      justify-content-end
                      align-items-center
                  "
                  cols="12"
                >
                  <span
                    class="
                      text-subtitle-1
                      font-weight-bold
                      ma-0
                      text-decoration-underline
                      pointer
                    "
                    @click="showResetPassword"
                  >
                    Lupa kata laluan
                  </span>
                </v-col>
              </v-row>

              <v-divider
                class="mt-8"
              ></v-divider>

              <v-btn
                depressed
                x-large
                block
                class="mt-8"
                color="primary"
                elevation="4"
                :loading="isLoadingPassword"
                type="submit"
                @click="onFinishPassword()"
              >
                <span
                  class="font-weight-bold"
                >
                  Log Masuk
                </span>
              </v-btn>
            </v-form>
          </ValidationObserver>
        </div>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate size="64"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>
<script>
import {
  required,
  min,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} perlu dinyatakan",
});

extend("min", {
  ...min,
  message: "{_field_} perlu minima 12 digits",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default() {
        return {
          email: "",
          password: "",
        };
      },
    },
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      resetPassword: false,
      isLoadingPassword: false,
      overlay: false,
    };
  },

  computed: {
    showPasswordDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  mounted() { },

  methods: {
    closePassword() {
      this.showPasswordDialog = false;
      this.overlay = false;
    },

    showResetPassword() {
      this.resetPassword = true;
      this.$emit("showResetPassword", this.resetPassword);
    },

    onFinishPassword() {
      this.overlay = true;
      this.$refs.passwordAuth.validate()
        .then((response) => {
          if (response) {
            this.isLoadingPassword = true;

            this.$emit("onFinishPassword", {
              email: this.email,
              password: this.password,
            });
          }
        })
        .finally(() => {
          this.overlay = false;
          this.isLoadingPassword = false;
        });
    },
  },
};
</script>

<style>
.v-text-field--outlined input {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 14px;
}

.pointer {
  color: cornflowerblue;
  cursor: pointer;
}

.pointer-disable {
  cursor: pointer;
}
</style>
