<template>
  <!--begin::Signin-->
  <v-container
    class="body"
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="7"
        lg="6"
        xl="4"
      >
        <div
          class="card card-custom gutter-b"
        >
          <div
            class="pa-8"
          >
            <!--begin::Form-->
            <center>
              <v-img
                :width="150"
                position="center"
                align="center"
                src="/media/logos/logo-kowamas-200x63.png"
              ></v-img>
            </center>
            <p
              class="mb-8 h3 font-weight-bold text-center"
            >
              myE-Wawasan
            </p>
            <v-divider
            ></v-divider>
            <center
            >
              <p
                class="h6 font-weight-bold"
              >
                Sila masukkan nombor MyKad 12 digit anda untuk proses
                identifikasi
              </p>
            </center>

            <ValidationObserver
              ref="authLogin"
            >
              <v-form
                class="font-weight-bold font-size-sm"
                @submit.prevent="onSubmit()"
              >
                <v-row
                >
                  <v-col
                    cols="12"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="No. Kad Pengenalan"
                      rules="required|min:14"
                    >
                      <v-text-field
                        v-model="form.nric"
                        v-mask="mask_nric"
                        label="No. Kad Pengenalan (MyKad)"
                        class="text-field-solo-label-top"
                        prepend-icon="mdi-account-card-details"
                        :error-messages="errors"
                        :autofocus="nricAutoFocus"
                        @change="verify_nric()"
                        outlined
                        clearable
                        required
                        shaped
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <!--   -->
                  <!-- <v-col
                    class="py-0 d-flex flex-wrap justify-content-end align-items-center"
                    cols="12"
                  >
                    <span
                      class="text-subtitle-1 font-weight-bold ma-0 text-decoration-underline pointer"
                      @click="goToGuestLogin()"
                    >
                      Lupa kata laluan?
                    </span>
                  </v-col> -->

                  <!-- <v-col class="d-flex flex-wrap justify-content-end align-items-center" cols="12">
                  </v-col> -->
                </v-row>
              </v-form>
            </ValidationObserver>
            <!--end::Form-->
            <v-divider
            ></v-divider>

            <v-btn
              v-if="buttonShow"
              class="mt-8"
              depressed
              elevation="4"
              x-large
              block
              color="primary"
              :loading="isLoading"
              @click="onSubmit()"
            >
              <span
                class="font-weight-bold"
              >
                Teruskan
              </span>
            </v-btn>
          </div>
        </div>

        <UserRegistrationDialog
          v-model="showUserRegDialog"
        ></UserRegistrationDialog>

        <ConfirmationDialog
          v-model="showConfirmationDialog"
          title="Maaf"
          content="Emel dan/atau Kata laluan yang diberikan tidak sepadan dengan rekod kami. Sila semak semula dan cuba lagi."
          :showProceedButton="false"
          :showBackButton="true"
          backButton="Kembali"
        ></ConfirmationDialog>

        <OTPPrompt
          v-model="showOTP"
          :params="msgOTP"
          @onFinishOTP="onFinishOTP"
          @onErrorOTP="onErrorOTP"
        ></OTPPrompt>

        <PasswordDialog
          v-model="showPassword"
          :params="msgPwd"
          @onFinishPassword="onFinishPassword"
          @showResetPassword="showResetPassword"
        ></PasswordDialog>

        <PasswordAndOTPDialog
          v-model="showPasswordAndOTP"
          @showOTPDialog="showOTPDialog"
          @onFinishPassword="onFinishPassword"
        ></PasswordAndOTPDialog>

        <InfoMsgDialog
          v-model="openInfoMsgDialog"
          title="Info"
          proceedButton="OK"
          :content="msgINFO.message"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          @onProceed="openInfoMsgDialog = false"
        ></InfoMsgDialog>

        <ErrorMsgDialog
          v-model="openErrorMsgDialog"
          title="Ralat"
          proceedButton="OK"
          :content="msgERR.message"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          @onProceed="openErrorMsgDialog = false"
        ></ErrorMsgDialog>

        <ResetPasswordDialog
          v-model="openResetPasswordDialog"
          @onResetPassword="onResetPassword"
        ></ResetPasswordDialog>
      </v-col>
    </v-row>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
  <!--end::Signin-->
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import Swal from "sweetalert2";
import OTPPrompt from "@/view/pages/widgets/OTPPrompt.vue";
import PasswordDialog from "@/view/pages/widgets/PasswordDialog.vue";
import ResetPasswordDialog from "@/view/pages/widgets/ResetPasswordDialog.vue";
import PasswordAndOTPDialog from "@/view/pages/widgets/PasswordAndOTPDialog.vue";
import UserRegistrationDialog from "@/view/pages/widgets/UserRegistrationDialog.vue";

import {
  INITSTEP,
  LOGIN,
  SET_AUTH,
  SET_OTP_AUTH,
  CLEAR_LOGIN,
} from "@/core/services/store/auth.module";

import {
  GET_LOOKUPS,
} from "@/core/services/store/lookup.module";

import {
  ord,
  errorMessages,
  is_valid_nric,
} from "@/core/services/helper.service";

import {
  VERIFY_OTP,
} from "@/core/services/store/otp.module";

import {
  required,
  min,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  REQUEST_RESET_PASSWORD,
} from "@/core/services/store/notification.module";

import {
  GET_TESTMODE,
  SET_TESTMODE,
  GET_ONLYACCEPTREALNRIC,
  SET_ONLYACCEPTREALNRIC,
} from "@/core/services/store/setting.module";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} diperlukan untuk membuat semakan?.",
});

extend("min", {
  ...min,
  message: "{_field_} perlu minima 12 digits",
});

extend("max", {
  ...max,
  message: "{_field_} melebihi angka yang ditetapkan",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "Login",
  components: {
    ValidationObserver,
    ValidationProvider,
    UserRegistrationDialog,
    OTPPrompt,
    PasswordDialog,
    PasswordAndOTPDialog,
    ResetPasswordDialog,
  },
  data() {
    return {
      next: null,
      showOTP: false,
      overlay: false,
      isLoading: false,
      buttonShow: true,
      nric_autoFocus: true,
      showUserRegDialog: false,
      showConfirmationDialog: false,
      showPassword: false,
      showPasswordAndOTP: false,
      openResetPasswordDialog: false,

      // Error Popup
      openErrorMsgDialog: false,
      openInfoMsgDialog: false,

      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },

      form: {
        nric: "",
        password: "",
      },

      mask_nric: "######-##-####",

      msgOTP: {
        show: false,
        message: "",
        nric: "",
        run: 0,
        route: {},
      },

      msgPwd: {
        message: "",
        nric: "",
        email: "",
      },

      msgINFO: {
        message: "",
        nric: "",
        email: "",
      },
    };
  },

  mounted() {
    if (this?.$route?.query?.next) {
      this.next = this.$route.query.next;
    }
    this.$store.dispatch(GET_TESTMODE);
    this.$store.dispatch(GET_ONLYACCEPTREALNRIC);
  },

  computed: {
    ...mapGetters([
      "response",
      "testMode",
      "onlyAcceptRealNRIC"
    ]),

    ...mapState({
      errors: (state) => state.auth.errors,
      testMode: (state) => state.setting.testMode,
      onlyAcceptRealNRIC: (state) => state.setting.onlyAcceptRealNRIC,
    }),

    nricAutoFocus: {
      get: function () {
        return this.nric_autoFocus;
      },

      set: function (val) {
        this.nric_autoFocus = val;
        //nfh-console.log('COMPUTED val, this.nric_autoFocus: ', val, this.nric_autoFocus);
      }
    },
  },

  methods: {
    goToGuestLogin() {
      this.$router.push("/reset-password");
    },
    // showUserRegDialogFromChild() {
    //   this.showUserRegDialog = true;
    // },
    // onProceedConfirmationDialog() {
    //   this.showUserRegDialog = true;
    //   this.showConfirmationDialog = false;
    // },

    showResetPassword(value) {
      this.openResetPasswordDialog = value;
    },

    async onResetPassword(value) {
      const params = {
        email: value,
        from: this.$route.path,
        next: "/login",
      };

      if (value) {
        await this.$store.dispatch(REQUEST_RESET_PASSWORD, params)
          .then((response) => {
            if (response.data.success == true) {
              this.openInfoMsgDialog = true;
              this.msgINFO.message   = response.data.message;
            }
            else {
              this.openErrorMsgDialog = true;
              this.msgERR.message     = response.data.message;
            }
          });
      }
    },

    async onFinishPassword(crendentials) {
      const nric     = this.form.nric;
      const email    = crendentials.email;
      const password = crendentials.password;
      const routeTo  = { name: "Dashboard" };
      const _context = {
        nric: this.form.nric,
        email: crendentials.email,
        password: crendentials.password,
      };

      await this.$store.dispatch(LOGIN, _context)
        .then(async (resp) => {
          if (resp.data.success) {
            // localStorage.setItem("uid", resp.data.ms);
            await this.$store.commit(SET_AUTH, resp.data);
            await this.$store.dispatch(GET_LOOKUPS);

            if (this?.$route?.query?.act) {
              routeTo.name  = "Redirect";
              routeTo.query = this.$route.query;
            }

            try {
              // xxconsole.log("LOGIN Router.Replace: LOGIN", this.$route.query);
              await this.$router.replace(routeTo);
            }
            catch (err) {
              // xxconsole.log("LOGIN Router.Replace: LOGIN -> ERROR", err);
              if (!(err instanceof NavigationDuplicated)) {
                throw err;
              }
            }
          }
          else {
            this.showConfirmationDialog = true;
          }
        })
        .catch((err) => {
        });
    },

    async onFinishOTP(value) {
      // this.$store
      //   .dispatch(LOGIN, { nric, password })
      //   // go to which page after successfully login
      //   .then(() => this.$router.push("/"));

      this.isLoading = true;
      this.overlay  = true;
      const routeTo = {
        name: "Dashboard",
      };
      const params  = {
        nric: this.form.nric,
        otp: value,
      };

      await this.$store.dispatch(VERIFY_OTP, params)
        .then(async (resp) => {
          if (resp?.data?.success) {
            this.showOTP = false;
            // this.$store
            //   .dispatch(LOGIN_WITH_OTP, {
            //     nric: this.form.nric,
            //   })
            //   .then(async (resp) => {
            // localStorage.setItem("uid", resp.data.data.user.id);
            // localStorage.setItem("uid", resp.data.ms);
            await this.$store.commit(SET_OTP_AUTH, resp.data);
            await this.$store.dispatch(GET_LOOKUPS);

            if (this?.$route?.query?.act) {
              routeTo.query = this.$route.query;
            }

            this.overlay    = false;
            this.isLoading  = false;
            this.buttonShow = false;
            await this.$router.push(routeTo);
            //  });
          }
          else {
            switch (resp.data.errcode) {
              case "otp_unmatched_or_expired":
                errorMessages("OTP tidak sepadan atau telah tamat tempoh.");
                break;

              case "authentication_failed":
                errorMessages("Could not authenticate");
                break;

              case "authentication_unexpected":
              case "catch_error":

              default:
                errorMessages(
                  "Berlaku ralat ketika membuat pengesahan data."
                );
            }
            // await this.$router.push({
            //   name: "Dashboard",
            // });
          }
        })
        .catch((err) => {
          // xxconsole.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    async onErrorOTP(value) {
      errorMessages(value);
    },

    showOTPDialog() {
      this.nricAutoFocus = false;
      this.showPassword  = false;
      this.showOTP       = true;
    },

    async verify_nric() {
      await this.$store.dispatch(GET_TESTMODE);
      await this.$store.dispatch(GET_ONLYACCEPTREALNRIC);

      if (this.form.nric) {
        if (!is_valid_nric(this.form.nric, this.onlyAcceptRealNRIC)) {
          this.msgERR.message = "Sila pastikan nombor MyKad yang dimasukkan adalah nombor yang sah!";
          this.openErrorMsgDialog = true;
        }
      }
    },

    async onSubmit() {
      this.overlay = true;

      await this.$store.dispatch(GET_TESTMODE);
      await this.$store.dispatch(GET_ONLYACCEPTREALNRIC);

      if (!is_valid_nric(this.form.nric, this.onlyAcceptRealNRIC)) {
        this.msgERR.message = "Sila pastikan nombor MyKad yang dimasukkan adalah nombor yang sah!";
        this.openErrorMsgDialog = true;
        this.overlay = false;
        throw this.msgERR.message;
      }
      else {
        this.$refs.authLogin.validate()
          .then(async (validated) => {
            if (validated) {
              this.isLoading = true;

              // this.$store.dispatch(LOGOUT);
              this.$store.dispatch(CLEAR_LOGIN);

              const nric = this.form.nric;

              await this.$store.dispatch(INITSTEP, { nric })
                .then(async (resp) => {
                  switch (resp?.data?.next) {
                    case "form_u":
                    case "form_p":
                      await this.$store.commit(SET_AUTH, resp.data);
                      await this.$store.dispatch(GET_LOOKUPS);
                      this.showOTP      = false;
                      this.showPassword = false;
                      this.isLoading    = false;
                      this.overlay      = false;
                      await this.$router.push({ name: "Form" });
                      break;

                    case "ask_pwd_u":
                    case "ask_pwd_p":
                      // this.msgPwd.message = resp.data.greet;
                      this.showOTP      = false;
                      this.showPassword = true;
                      this.isLoading    = false;
                      this.overlay      = false;
                      break;

                    case "ask_otp_u":
                    case "ask_otp_m":
                      this.msgOTP.message = resp.data.message + (resp?.data?.speedms ? " [" + resp.data.speedms + "]" : "");
                      this.msgOTP.nric    = this.form.nric;
                      this.msgOTP.route   = this.$route;
                      // this.msgOTP.run = ord(resp.data.ref[0]);
                      // xxconsole.log(this.msgOTP.run);
                      this.showOTP      = true;
                      this.showPassword = false;
                      this.isLoading    = false;
                      this.overlay      = false;
                      break;

                    case "ask_nric_p":
                    case "ask_nric_u":
                    case "ask_nric_m":
                      this.showOTP            = false;
                      this.isLoading          = false;
                      this.showPassword       = false;
                      this.overlay            = false;
                      this.msgERR.message     = resp?.data?.message;
                      this.openErrorMsgDialog = true;
                      break;

                    default:
                      this.showPassword = false;
                      this.showOTP      = false;
                      this.isLoading    = false;
                      this.overlay      = false;
                  }

                  // if (resp.data.success == true) {
                  //   //console.log("User in DB exists");
                  //   this.$store.commit(SET_RESPONSE, resp.data);
                  //   if (
                  //     resp.data.data.set_password == true &&
                  //     resp.data.data.set_phone_num == true
                  //   ) {
                  //     //console.log("SET PASSWORD TRUE, SET PHONE NUM TRUE");
                  //     this.showPassword = true;
                  //     this.isLoading = false;
                  //   } else if (
                  //     resp.data.data.set_password == true &&
                  //     resp.data.data.set_phone_num == false
                  //   ) {
                  //     //console.log("SET PASSWORD TRUE, SET PHONE NUM FALSE");
                  //     this.showPassword = true;
                  //     this.isLoading = false;
                  //   } else if (
                  //     resp.data.data.set_password == false &&
                  //     resp.data.data.set_phone_num == true
                  //   ) {
                  //     //console.log("SET PASSWORD FALSE, SET PHONE NUM TRUE");
                  //     this.showOTP == true;
                  //     this.isLoading = false;
                  //   } else if (
                  //     resp.data.data.set_password == false &&
                  //     resp.data.data.set_phone_num == false
                  //   ) {
                  //     //console.log("SET PASSWORD FALSE, SET PHONE NUM FALSE");
                  //     this.$store
                  //       .dispatch(REGISTER_EXISTING_USER, { nric })
                  //       .then((resp) => {
                  //         this.isLoading = false;
                  //         if (resp.success == true) {
                  //           this.$router.push({ name: "Form"});
                  //         }
                  //       });
                  //   }
                  // } else {
                  //   await this.$store
                  //     .dispatch(USERLXCHECKING, { nric })
                  //     .then((resp) => {
                  //       //console.log(resp.data.success);
                  //       if (resp.data.success == true) {
                  //         //console.log("LX MEMBER FOUND");
                  //         // this.$store.dispatch(GETMEMBERSHIPANDREGISTER, { nric }).then(() => {
                  //         //   this.$router.push("/");
                  //         // });
                  //       } else {
                  //         this.$store.dispatch(REGISTER, { nric }).then(() => {
                  //           this.$router.push({ name: "Form" });
                  //         });
                  //       }
                  //     });
                  // }
                })
                .catch((err) => {
                  this.msgERR.message = errorMessages(err.message);
                  this.openErrorMsgDialog = true;
                  // xxconsole.error(err);
                  this.overlay = false;
                  this.showOTP = false;
                  this.isLoading = false;
                  this.showPassword = false;
                });
            } else {
              this.overlay = false;
            }
          })
          .catch((err) => {
            this.msgERR.message = errorMessages(err.message);
            this.openErrorMsgDialog = true;
            this.overlay = false;
            this.showOTP = false;
            this.isLoading = false;
            this.showPassword = false;
          });
      }
    },

    onProceed() {
      this.proceedButton = !this.proceedButton;
    },
  },
};
</script>
<style scoped>
.body {
  /* background-color: #f3f6f9; */
  background-image: url("/media/bg/bg-login.png");
  background-size: cover;
  background-color: #f5f5f9;
}

.v-text-field input {
  font-size: 1.2em;
}

.v-text-field--outlined {
  font-size: 18px;
}

.v-text-field--outlined label {
  font-size: 18px;
}

.pointer {
  color: cornflowerblue;
  cursor: pointer;
}
</style>
