var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "400", persistent: "" },
          model: {
            value: _vm.showPasswordAndOTPDialog,
            callback: function ($$v) {
              _vm.showPasswordAndOTPDialog = $$v
            },
            expression: "showPasswordAndOTPDialog",
          },
        },
        [
          _c("v-card", [
            _c(
              "div",
              { staticClass: "pa-6" },
              [
                _c(
                  "ValidationObserver",
                  { ref: "passwordAuth" },
                  [
                    _c(
                      "v-form",
                      {
                        staticClass: "font-weight-bold font-size-sm",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onFinishPassword()
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "h6 font-weight-bold" }, [
                          _vm._v("Kata laluan"),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Kata laluan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": _vm.showPassword
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              "error-messages": errors,
                                              type: _vm.showPassword
                                                ? "text"
                                                : "password",
                                              outlined: "",
                                              clearable: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showPassword =
                                                  !_vm.showPassword
                                              },
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function ($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "py-0 d-flex flex-wrap justify-content-end align-items-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-subtitle-1 font-weight-bold ma-0 text-decoration-underline pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showOTPDialog()
                                      },
                                    },
                                  },
                                  [_vm._v(" Guna OTP ")]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex flex-wrap justify-content-end align-items-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    on: {
                                      click: function ($event) {
                                        return _vm.closePassword()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(" Tutup ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      depressed: "",
                                      color: "primary",
                                      loading: _vm.isLoadingPassword,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onFinishPassword()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Daftar")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }