var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650", persistent: "" },
          model: {
            value: _vm.displayUserRegDialog,
            callback: function ($$v) {
              _vm.displayUserRegDialog = $$v
            },
            expression: "displayUserRegDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c(
                "ValidationObserver",
                { ref: "userRegister" },
                [
                  _c(
                    "v-form",
                    { staticClass: "font-weight-bold font-size-sm" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "primary--text font-weight-bold h6",
                                },
                                [_vm._v("Maklumat Diri")]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Nama Penuh",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "text-field",
                                          attrs: {
                                            label: "Nama Penuh",
                                            "error-messages": errors,
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.full_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "full_name",
                                                $$v
                                              )
                                            },
                                            expression: "user.full_name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "No. Kad Pengenalan",
                                  rules: "required|min:14",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: _vm.mask_nric,
                                              expression: "mask_nric",
                                            },
                                          ],
                                          staticClass: "text-field",
                                          attrs: {
                                            label: "No. Kad Pengenalan",
                                            "error-messages": errors,
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.nric,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "nric", $$v)
                                            },
                                            expression: "user.nric",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("vue-tel-input-vuetify", {
                                attrs: {
                                  defaultCountry: "MY",
                                  onlyCountries: ["MY"],
                                  placeholder: "No. Telefon",
                                  label: "No. Telefon",
                                  outlined: "",
                                  dense: "",
                                  clearable: "",
                                  required: "",
                                  translations: {
                                    countrySelectorLabel: "",
                                    countrySelectorError: "",
                                    phoneNumberLabel: "No. Telefon",
                                    example: "Contoh :",
                                  },
                                },
                                model: {
                                  value: _vm.user.phone_number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "phone_number", $$v)
                                  },
                                  expression: "user.phone_number",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "E-emel", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "text-field",
                                          attrs: {
                                            label: "E-mel",
                                            "error-messages": errors,
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "email", $$v)
                                            },
                                            expression: "user.email",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "primary--text font-weight-bold h6",
                                },
                                [_vm._v("Alamat Rumah")]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Unit/Lot/No./Tingkat",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "text-field",
                                          attrs: {
                                            hint: "Contoh A1-2-3",
                                            "error-messages": errors,
                                            label: "Unit/Lot/No./Tingkat",
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.address.unit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user.address,
                                                "unit",
                                                $$v
                                              )
                                            },
                                            expression: "user.address.unit",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Jalan/Apartment/Taman",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "text-field",
                                          attrs: {
                                            hint: "Jalan Bahagia, Kampung Sentosa",
                                            "error-messages": errors,
                                            label: "Jalan / Apartment / Taman",
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.address.street,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user.address,
                                                "street",
                                                $$v
                                              )
                                            },
                                            expression: "user.address.street",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Poskod", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: _vm.mask_postcode,
                                              expression: "mask_postcode",
                                            },
                                          ],
                                          staticClass: "text-field",
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Poskod",
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.address.postcode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user.address,
                                                "postcode",
                                                $$v
                                              )
                                            },
                                            expression: "user.address.postcode",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Bandar", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "text-field",
                                          attrs: {
                                            "error-messages": errors,
                                            rules: _vm.rulesLength.city,
                                            hint: "Bandar mengikut poskod",
                                            label: "Bandar",
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.address.city,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user.address,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression: "user.address.city",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Negeri", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.states,
                                            "item-text": "name",
                                            "item-value": "name",
                                            "error-messages": errors,
                                            label: "Negeri",
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.user.address.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user.address,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "user.address.state",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-wrap justify-content-end align-items-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeUserRegDialog()
                                    },
                                  },
                                },
                                [_vm._v(" Tutup ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    loading: _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onProceed()
                                    },
                                  },
                                },
                                [_vm._v(" Daftar ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }