<template>
  <div class="text-center">
    <v-dialog v-model="displayUserRegDialog" width="650" persistent>
      <v-card class="pa-4">
        <ValidationObserver ref="userRegister">
          <v-form class="font-weight-bold font-size-sm">
            <v-row>
              <v-col cols="12" md="12">
                <p class="primary--text font-weight-bold h6">Maklumat Diri</p>
                <v-divider class="mt-0"></v-divider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nama Penuh"
                  rules="required"
                >
                  <v-text-field
                    label="Nama Penuh"
                    class="text-field"
                    v-model="user.full_name"
                    :error-messages="errors"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="No. Kad Pengenalan"
                  rules="required|min:14"
                >
                  <v-text-field
                    label="No. Kad Pengenalan"
                    class="text-field"
                    v-model="user.nric"
                    :error-messages="errors"
                    v-mask="mask_nric"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <vue-tel-input-vuetify
                  v-model="user.phone_number"
                  defaultCountry="MY"
                  :onlyCountries="['MY']"
                  placeholder="No. Telefon"
                  label="No. Telefon"
                  outlined dense clearable required
                  :translations="{
                    countrySelectorLabel: '',
                    countrySelectorError: '',
                    phoneNumberLabel: 'No. Telefon',
                    example: 'Contoh :',
                  }"
                ></vue-tel-input-vuetify>
              </v-col>

              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="E-emel"
                  rules="required"
                >
                  <v-text-field
                    label="E-mel"
                    class="text-field"
                    v-model="user.email"
                    :error-messages="errors"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="12">
                <p class="primary--text font-weight-bold h6">Alamat Rumah</p>
                <v-divider class="mt-0"></v-divider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Unit/Lot/No./Tingkat"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address.unit"
                    class="text-field"
                    hint="Contoh A1-2-3"
                    :error-messages="errors"
                    label="Unit/Lot/No./Tingkat"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="8">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Jalan/Apartment/Taman"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address.street"
                    class="text-field"
                    hint="Jalan Bahagia, Kampung Sentosa"
                    :error-messages="errors"
                    label="Jalan / Apartment / Taman"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Poskod"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address.postcode"
                    class="text-field"
                    :error-messages="errors"
                    v-mask="mask_postcode"
                    label="Poskod"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Bandar"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address.city"
                    class="text-field"
                    :error-messages="errors"
                    :rules="rulesLength.city"
                    hint="Bandar mengikut poskod"
                    label="Bandar"
                    outlined
                    clearable
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Negeri"
                  rules="required"
                >
                  <v-select
                    v-model="user.address.state"
                    :items="states"
                    item-text="name"
                    item-value="name"
                    :error-messages="errors"
                    label="Negeri"
                    outlined
                    clearable
                    dense
                    required
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                class="d-flex flex-wrap justify-content-end align-items-center"
                cols="12"
              >
                <v-btn class="mr-4" @click="closeUserRegDialog()">
                  Tutup
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  :loading="isLoading"
                  @click="onProceed()"
                >
                  Daftar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, min, max, numeric } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("min", {
  ...min,
  message: "{_field_} tidak mencukupi",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      rulesLength: {
        name: [(v) => v.length <= 40 || "Max 40 huruf"],
        city: [(v) => v.length <= 30 || "Max 30 huruf"],
      },
      mask_nric: "######-##-####",
      mask_postcode: "#####",
      user: {
        full_name: "",
        nric: "",
        email: "",
        phone_number: "",
        address: {
          unit: "",
          street: "",
          postcode: "",
          city: "",
          state: "",
        },
      },
      states: [
        {
          name: "JOHOR",
          myKadCode: ["01", "21", "22", "23", "24"],
        },
        {
          name: "KEDAH",
          myKadCode: ["02", "25", "26", "27"],
        },
        {
          name: "KELANTAN",
          myKadCode: ["03", "28", "29"],
        },
        {
          name: "MELAKA",
          myKadCode: ["04", "30"],
        },
        {
          name: "NEGERI SEMBILAN",
          myKadCode: ["05", "31", "59"],
        },
        {
          name: "PAHANG",
          myKadCode: ["06", "32", "33"],
        },
        {
          name: "PULAU PINANG",
          myKadCode: ["07", "34", "35"],
        },
        {
          name: "PERAK",
          myKadCode: ["08", "36", "37", "38", "39"],
        },
        {
          name: "PERLIS",
          myKadCode: ["09", "40"],
        },
        {
          name: "SELANGOR",
          myKadCode: ["10", "41", "42", "43", "44"],
        },
        {
          name: "TERENGGANU",
          myKadCode: ["11", "45", "46"],
        },
        {
          name: "SABAH",
          myKadCode: ["12", "47", "48", "49"],
        },
        {
          name: "SARAWAK",
          myKadCode: ["13", "50", "51", "52", "53"],
        },
        {
          name: "KUALA LUMPUR (WILAYAH PERSEKUTUAN)",
          myKadCode: ["14", "54", "55", "56", "57"],
        },
        {
          name: "LABUAN (WILAYAH PERSEKUTUAN)",
          myKadCode: ["15", "58"],
        },
        {
          name: "PUTRAJAYA (WILAYAH PERSEKUTUAN)",
          myKadCode: ["16"],
        },
      ],
    };
  },
  computed: {
    displayUserRegDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    closeUserRegDialog() {
      this.displayUserRegDialog = false;
    },
    onProceed() {
      this.isLoading = true;
      this.$emit("onProceed");
      this.isLoading = false;
    },
  },
};
</script>
<style>
.v-text-field--outlined input{
  font-size: 14px;
}

.v-text-field--outlined label {
  font-size: 14px;
}
</style>
