<template>
  <div class="text-center">
    <v-dialog
      v-model="showResetPasswordDialog"
      transition="dialog-top-transition"
      width="600"
    >
      <v-card elevation="8">
        <v-card-title class="headline font-weight-bold"
          >Set semula kata laluan</v-card-title
        >
        <v-card-subtitle class="pb-0">
          Masukkan e-mel anda untuk semakan
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>
          <div class="pa-8">
            <ValidationObserver ref="resetPassword">
              <v-form
                class="font-weight-bold font-size-sm"
                @submit.prevent="onResetPassword()"
              >
                <v-row>
                  <v-col cols="12" class="py-0 px-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="E-mel"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        outlined
                        dense
                        type="email"
                        required
                        hide-details="auto"
                        label="E-mel"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
        </v-card-text>
        <v-card-actions class="pa-8">
          <v-btn depressed x-large block color="primary" elevation="4" @click="onResetPassword"
            >Sahkan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, min, max, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} perlu dinyatakan",
});

extend("min", {
  ...min,
  message: "{_field_} perlu minima 12 digits",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    showResetPasswordDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onResetPassword() {
      this.$refs.resetPassword.validate().then((success) => {
        if (success) {
          // xxconsole.log(this.email);
          this.$emit('onResetPassword', this.email);
        }
      })
    }
  },
};
</script>
<style>
/* .v-text-field--outlined input {
  font-size: 11pt;
}
.v-text-field--outlined label {
  font-size: 14px;
} */
</style>