var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-top-transition", width: "600" },
          model: {
            value: _vm.showResetPasswordDialog,
            callback: function ($$v) {
              _vm.showResetPasswordDialog = $$v
            },
            expression: "showResetPasswordDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { elevation: "8" } },
            [
              _c("v-card-title", { staticClass: "headline font-weight-bold" }, [
                _vm._v("Set semula kata laluan"),
              ]),
              _c("v-card-subtitle", { staticClass: "pb-0" }, [
                _vm._v(" Masukkan e-mel anda untuk semakan "),
              ]),
              _c("v-divider"),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "pa-8" },
                  [
                    _c(
                      "ValidationObserver",
                      { ref: "resetPassword" },
                      [
                        _c(
                          "v-form",
                          {
                            staticClass: "font-weight-bold font-size-sm",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.onResetPassword()
                              },
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 px-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "E-mel",
                                        rules: "required|email",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "error-messages": errors,
                                                  outlined: "",
                                                  dense: "",
                                                  type: "email",
                                                  required: "",
                                                  "hide-details": "auto",
                                                  label: "E-mel",
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function ($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        "x-large": "",
                        block: "",
                        color: "primary",
                        elevation: "4",
                      },
                      on: { click: _vm.onResetPassword },
                    },
                    [_vm._v("Sahkan")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }