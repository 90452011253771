var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "body", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "10", md: "7", lg: "6", xl: "4" } },
            [
              _c("div", { staticClass: "card card-custom gutter-b" }, [
                _c(
                  "div",
                  { staticClass: "pa-8" },
                  [
                    _c(
                      "center",
                      [
                        _c("v-img", {
                          attrs: {
                            width: 150,
                            position: "center",
                            align: "center",
                            src: "/media/logos/logo-kowamas-200x63.png",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-8 h3 font-weight-bold text-center" },
                      [_vm._v(" myE-Wawasan ")]
                    ),
                    _c("v-divider"),
                    _c("center", [
                      _c("p", { staticClass: "h6 font-weight-bold" }, [
                        _vm._v(
                          " Sila masukkan nombor MyKad 12 digit anda untuk proses identifikasi "
                        ),
                      ]),
                    ]),
                    _c(
                      "ValidationObserver",
                      { ref: "authLogin" },
                      [
                        _c(
                          "v-form",
                          {
                            staticClass: "font-weight-bold font-size-sm",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.onSubmit()
                              },
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "No. Kad Pengenalan",
                                        rules: "required|min:14",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: _vm.mask_nric,
                                                    expression: "mask_nric",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-field-solo-label-top",
                                                attrs: {
                                                  label:
                                                    "No. Kad Pengenalan (MyKad)",
                                                  "prepend-icon":
                                                    "mdi-account-card-details",
                                                  "error-messages": errors,
                                                  autofocus: _vm.nricAutoFocus,
                                                  outlined: "",
                                                  clearable: "",
                                                  required: "",
                                                  shaped: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.verify_nric()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form.nric,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "nric",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.nric",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _vm.buttonShow
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mt-8",
                            attrs: {
                              depressed: "",
                              elevation: "4",
                              "x-large": "",
                              block: "",
                              color: "primary",
                              loading: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(" Teruskan "),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("UserRegistrationDialog", {
                model: {
                  value: _vm.showUserRegDialog,
                  callback: function ($$v) {
                    _vm.showUserRegDialog = $$v
                  },
                  expression: "showUserRegDialog",
                },
              }),
              _c("ConfirmationDialog", {
                attrs: {
                  title: "Maaf",
                  content:
                    "Emel dan/atau Kata laluan yang diberikan tidak sepadan dengan rekod kami. Sila semak semula dan cuba lagi.",
                  showProceedButton: false,
                  showBackButton: true,
                  backButton: "Kembali",
                },
                model: {
                  value: _vm.showConfirmationDialog,
                  callback: function ($$v) {
                    _vm.showConfirmationDialog = $$v
                  },
                  expression: "showConfirmationDialog",
                },
              }),
              _c("OTPPrompt", {
                attrs: { params: _vm.msgOTP },
                on: {
                  onFinishOTP: _vm.onFinishOTP,
                  onErrorOTP: _vm.onErrorOTP,
                },
                model: {
                  value: _vm.showOTP,
                  callback: function ($$v) {
                    _vm.showOTP = $$v
                  },
                  expression: "showOTP",
                },
              }),
              _c("PasswordDialog", {
                attrs: { params: _vm.msgPwd },
                on: {
                  onFinishPassword: _vm.onFinishPassword,
                  showResetPassword: _vm.showResetPassword,
                },
                model: {
                  value: _vm.showPassword,
                  callback: function ($$v) {
                    _vm.showPassword = $$v
                  },
                  expression: "showPassword",
                },
              }),
              _c("PasswordAndOTPDialog", {
                on: {
                  showOTPDialog: _vm.showOTPDialog,
                  onFinishPassword: _vm.onFinishPassword,
                },
                model: {
                  value: _vm.showPasswordAndOTP,
                  callback: function ($$v) {
                    _vm.showPasswordAndOTP = $$v
                  },
                  expression: "showPasswordAndOTP",
                },
              }),
              _c("InfoMsgDialog", {
                attrs: {
                  title: "Info",
                  proceedButton: "OK",
                  content: _vm.msgINFO.message,
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                },
                on: {
                  onProceed: function ($event) {
                    _vm.openInfoMsgDialog = false
                  },
                },
                model: {
                  value: _vm.openInfoMsgDialog,
                  callback: function ($$v) {
                    _vm.openInfoMsgDialog = $$v
                  },
                  expression: "openInfoMsgDialog",
                },
              }),
              _c("ErrorMsgDialog", {
                attrs: {
                  title: "Ralat",
                  proceedButton: "OK",
                  content: _vm.msgERR.message,
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                },
                on: {
                  onProceed: function ($event) {
                    _vm.openErrorMsgDialog = false
                  },
                },
                model: {
                  value: _vm.openErrorMsgDialog,
                  callback: function ($$v) {
                    _vm.openErrorMsgDialog = $$v
                  },
                  expression: "openErrorMsgDialog",
                },
              }),
              _c("ResetPasswordDialog", {
                on: { onResetPassword: _vm.onResetPassword },
                model: {
                  value: _vm.openResetPasswordDialog,
                  callback: function ($$v) {
                    _vm.openResetPasswordDialog = $$v
                  },
                  expression: "openResetPasswordDialog",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }