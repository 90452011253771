var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-top-transition", width: "600" },
          model: {
            value: _vm.showPasswordDialog,
            callback: function ($$v) {
              _vm.showPasswordDialog = $$v
            },
            expression: "showPasswordDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "", elevation: "8" } },
            [
              _c("v-card-title", { staticClass: "headline font-weight-bold" }, [
                _vm._v(" Log Masuk "),
              ]),
              _c("v-divider"),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.params.message) + " "),
              ]),
              _c(
                "div",
                { staticClass: "pa-8" },
                [
                  _c(
                    "ValidationObserver",
                    { ref: "passwordAuth" },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "font-weight-bold font-size-sm",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onFinishPassword()
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("ValidationProvider", {
                                    attrs: { name: "E-mel", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "E-mel",
                                                "hide-details": "auto",
                                                "prepend-inner-icon":
                                                  "mdi-email-variant",
                                                "error-messages": errors,
                                                required: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value: _vm.email,
                                                callback: function ($$v) {
                                                  _vm.email = $$v
                                                },
                                                expression: "email",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Kata laluan",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Kata Laluan",
                                                "hide-details": "auto",
                                                "prepend-inner-icon":
                                                  "mdi-key-variant",
                                                "append-icon": _vm.showPassword
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                                type: _vm.showPassword
                                                  ? "text"
                                                  : "password",
                                                "error-messages": errors,
                                                required: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                              model: {
                                                value: _vm.password,
                                                callback: function ($$v) {
                                                  _vm.password = $$v
                                                },
                                                expression: "password",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "\n                    py-0\n                    d-flex\n                    flex-wrap\n                    justify-content-end\n                    align-items-center\n                ",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    text-subtitle-1\n                    font-weight-bold\n                    ma-0\n                    text-decoration-underline\n                    pointer\n                  ",
                                      on: { click: _vm.showResetPassword },
                                    },
                                    [_vm._v(" Lupa kata laluan ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mt-8" }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-8",
                              attrs: {
                                depressed: "",
                                "x-large": "",
                                block: "",
                                color: "primary",
                                elevation: "4",
                                loading: _vm.isLoadingPassword,
                                type: "submit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFinishPassword()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(" Log Masuk "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }