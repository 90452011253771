<template>
  <div class="text-center">
    <v-dialog v-model="showPasswordAndOTPDialog" width="400" persistent>
      <v-card>
        <div class="pa-6">
          <ValidationObserver ref="passwordAuth">
            <v-form
              class="font-weight-bold font-size-sm"
              @submit.prevent="onFinishPassword()"
            >
              <p class="h6 font-weight-bold">Kata laluan</p>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kata laluan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :error-messages="errors"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      clearable
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                    class="py-0 d-flex flex-wrap justify-content-end align-items-center"
                    cols="12"
                  >
                    <span
                      class="text-subtitle-1 font-weight-bold ma-0 text-decoration-underline pointer"
                      @click="showOTPDialog()"
                    >
                      Guna OTP
                    </span>
                  </v-col>

                <v-col
                  class="d-flex flex-wrap justify-content-end align-items-center"
                  cols="12"
                >
                  <v-btn class="mr-4" @click="closePassword()">
                    <span class="font-weight-bold"> Tutup </span>
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    :loading="isLoadingPassword"
                    @click="onFinishPassword()"
                  >
                    <span class="font-weight-bold">Daftar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, min, max, numeric } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("min", {
  ...min,
  message: "{_field_} tidak mencukupi",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      password: null,
      showPassword: false,
      isLoadingPassword: false,
      mask_nric: "######-##-####",
    };
  },
  computed: {
    showPasswordAndOTPDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  mounted() {},
  methods: {
    closePassword() {
      this.showPasswordAndOTPDialog = false;
    },
    onFinishPassword() {
      this.$refs.passwordAuth.validate().then((response) => {
        if (response) {
          this.isLoadingPassword = true;
          setTimeout(() => {
            this.$emit("onFinishPassword", this.password);
            this.isLoadingPassword = false;
          }, 2000);
        }
      });
    },
    showOTPDialog() {
        this.$emit("showOTPDialog");
    }
  },
};
</script>
<style>
.v-text-field--outlined input {
  font-size: 14px;
}
.v-text-field--outlined label {
  font-size: 14px;
}
.pointer {
  color: cornflowerblue;
  cursor: pointer;
}
.pointer-disable {
  cursor: pointer;
}
</style>
